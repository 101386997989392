// @flow
import type { ToastAction } from 'shared/types/toast';
import { TOAST_CONTEXT_SUCCESS } from './Toast.constants';

export const SHOW_TOAST: 'toast/SHOW' = 'toast/SHOW';
export const HIDE_TOAST: 'toast/HIDE' = 'toast/HIDE';

export const showToast = (
  message: string, context: string = TOAST_CONTEXT_SUCCESS
): ToastAction => ({
  type: SHOW_TOAST,
  message,
  context
});
export const hideToast = (): ToastAction => ({ type: HIDE_TOAST });
