// @flow
import React, { Suspense, lazy } from 'react';
import { Col, Grid, Row } from 'react-styled-flexboxgrid';
import { Switch } from 'react-router-dom';
import Toast from 'shared/components/Toast';
import PrivateRoute from 'shared/components/PrivateRoute';
import LoadingComponent from 'shared/components/LoadingComponent';

const MembershipCardPage = lazy(() => import(/* webpackChunkName: 'membershipCard' */'./pages/MembershipCard'));
const LoyaltyCardPage = lazy(() => import(/* webpackChunkName: 'loyaltyCard' */'./pages/LoyaltyCard'));
const LoginPage = lazy(() => import(/* webpackChunkName: 'login' */'./pages/Login'));
const AdminTabs = lazy(() => import(/* webpackChunkName: 'adminTabs' */'./pages/AdminTabs'));

const App = () => (
  <Grid>
    <Toast />
    <Row center="xs">
      <Col xs={12} sm={8} md={6} lg={5}>
        <Suspense fallback={<LoadingComponent />}>
          <Switch>
            <PrivateRoute exact path="/" component={MembershipCardPage} />
            <PrivateRoute path="/loyalty-card" component={LoyaltyCardPage} />
            <PrivateRoute path="/login" component={LoginPage} authenticated={false} />
            <PrivateRoute path="/admin" component={AdminTabs} />
          </Switch>
        </Suspense>
      </Col>
    </Row>
  </Grid>
);

export default App;
