// @flow
declare class process {
  static env: {
    NODE_ENV: 'development' | 'production',
    NODE_PATH: string,
    PUBLIC_URL: string
  }
}

export default {
  ...process.env,
  REACT_APP_API_ENDPOINT: process.env.NODE_ENV === 'production' ? `${window.location.origin}/api` : 'https://cors-anywhere.herokuapp.com/http://nsg.dev.norse.digital/api'
};
