import styled from 'styled-components';
import { IconWrapper, IconUtils } from 'shared/components/Icons';

export default styled(IconWrapper)`
  position: absolute;
  margin: auto;
  right: 12px;
  top: 0;
  bottom: 0;
  cursor: pointer;
  ${props => IconUtils.colorOverrideCss(props.theme.colors.white)}

  svg {
    width: 15px;
    height: 15px;
  }
`;
