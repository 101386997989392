// @flow
import * as React from 'react';
import AuthContext from 'shared/services/Auth/Auth.context';
import AuthService from 'shared/services/Auth/Auth.service';

export default <Config: {}>(
  Component: React.AbstractComponent<Config>
): React.AbstractComponent<$Diff<Config, { authService: typeof AuthService | void }>> => (
  props: $Diff<Config, { authService: typeof AuthService | void }>
) => (
  <AuthContext.Consumer>
    {authService => <Component authService={authService} {...props} />}
  </AuthContext.Consumer>
);
