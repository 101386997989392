// @flow
import type { Toast } from 'shared/types/toast';
import type { Action } from 'shared/types';
import { SHOW_TOAST, HIDE_TOAST } from './Toast.actions';
import { TOAST_CONTEXT_SUCCESS } from './Toast.constants';

const initialState: Toast = {
  visible: false,
  context: TOAST_CONTEXT_SUCCESS,
  message: ''
};

const toastReducer = (state: Toast = initialState, action: Action): Toast => {
  switch (action.type) {
    case SHOW_TOAST:
      return {
        ...state,
        visible: true,
        message: action.message,
        context: action.context
      };
    case HIDE_TOAST:
      return {
        ...state,
        visible: false
      };
    default:
      return state;
  }
};

export default toastReducer;
