// @flow
import jwtDecode from 'jwt-decode';
import config from 'config';
import type { Phone, Password } from 'shared/types/login';
import type {
  UserClub, UserClubs, AllClubs
} from 'shared/types/loyaltyCard';
import type { User } from 'shared/types/membershipCard';
import StorageService from 'shared/services/Storage';

type Constructor = {
  domain: string,
  accessTokenName: 'accessToken'
};

type DecodedToken = {
  exp: number,
  [string]: string | number | boolean
};

const { REACT_APP_API_ENDPOINT: API_ENDPOINT } = config;

class AuthService {
  domain: string

  accessTokenName: string

  constructor({ domain, accessTokenName }: Constructor) {
    this.domain = domain;
    this.accessTokenName = accessTokenName;
    this.fetch = this.fetch.bind(this);
    this.login = this.login.bind(this);
    this.checkIsAdmin = this.checkIsAdmin.bind(this);
    this.getUser = this.getUser.bind(this);
    this.getAllClubs = this.getAllClubs.bind(this);
    this.getUserClubs = this.getUserClubs.bind(this);
    this.registerClubVisit = this.registerClubVisit.bind(this);
  }

  login: (phone: Phone, password: Password) => Promise<string>

  login(phone: Phone, password: Password) {
    const basicToken = Buffer.from(`${phone}:${password}`).toString('base64');
    const headers: HeadersInit = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Basic ${basicToken}`
    };

    return fetch(`${this.domain}/login`, { headers, method: 'POST' })
      .then(this._checkStatus)
      .then(res => res.json())
      .then((res: { token: string }) => {
        StorageService.setItem(this.accessTokenName, res.token);
        const newHeaders: HeadersInit = {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${res.token}`
        };

        return fetch(`${this.domain}/isadmin`, { headers: newHeaders, method: 'GET' })
          .then(this._checkStatus)
          .then(response => response.json())
          .then((response: { isAdmin: boolean }) => Promise.resolve(response.isAdmin ? '/admin' : '/'));
      });
  }

  getUser: () => Promise<User>

  getUser() {
    return this.fetch(`${this.domain}/user`, { method: 'GET' })
      .then((res: User) => Promise.resolve(res));
  }

  getAllClubs: () => Promise<AllClubs<number>>

  getAllClubs() {
    return this.fetch(`${this.domain}/allclubs`, { method: 'GET' })
      .then((res: AllClubs<number>) => Promise.resolve(res));
  }

  getUserClubs: () => Promise<UserClubs>

  getUserClubs() {
    return this.fetch(`${this.domain}/userclubs`, { method: 'GET' })
      .then((res: UserClubs) => Promise.resolve(res));
  }

  registerClubVisit: (clubId: string) => Promise<UserClub>

  registerClubVisit(clubId: string) {
    return this.fetch(`${this.domain}/clubvisit`, {
      method: 'POST',
      body: JSON.stringify({
        clubId
      })
    })
      .then((res: UserClub) => Promise.resolve(res));
  }

  uploadUsers: (file: File) => Promise<Response>

  uploadUsers(file: File) {
    const formData = new FormData();
    formData.append('file', file);

    const headers = {
      Authorization: `Bearer ${StorageService.getItem(this.accessTokenName) || ''}`
    };

    return fetch(`${this.domain}/uploadUsers`, { headers, method: 'POST', body: formData })
      .then(this._checkStatus)
      .then(res => Promise.resolve(res));
  }

  loggedIn: () => boolean

  loggedIn() {
    const token = StorageService.getItem(this.accessTokenName);
    return !!token && !this.isTokenExpired(token);
  }

  checkIsAdmin: () => Promise<boolean>

  checkIsAdmin() {
    const headers: HeadersInit = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${StorageService.getItem(this.accessTokenName) || ''}`
    };

    return fetch(`${this.domain}/isadmin`, { headers, method: 'GET' })
      .then(this._checkStatus)
      .then(res => res.json())
      .then((res: { isAdmin: boolean }) => Promise.resolve(res.isAdmin));
  }

  isTokenExpired: (token: string) => boolean

  isTokenExpired(token: string): boolean {
    try {
      const decodedToken: DecodedToken = jwtDecode(token);
      if (decodedToken.exp * 1000 < Date.now()) {
        this.logout();
        return true;
      }
      return false;
    } catch (err) {
      this.logout();
      return true;
    }
  }

  logout: () => void

  logout(): void {
    StorageService.removeItem(this.accessTokenName);
  }

  fetch: (
    url: RequestInfo, options: RequestOptions, basicToken?: string
  ) => Promise<any>

  fetch(url: RequestInfo, options: RequestOptions) {
    const headers: HeadersInit = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${(StorageService.getItem(this.accessTokenName) || '')}`
    };

    return fetch(url, { headers, ...options })
      .then(this._checkStatus)
      .then(response => response.json());
  }

  _checkStatus: (response: Response) => Response

  // eslint-disable-next-line class-methods-use-this
  _checkStatus(response: Response) {
    if (response.status >= 200 && response.status < 300) { // Success status lies between 200 to 300
      return response;
    }
    const error: any = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

export default new AuthService({
  domain: API_ENDPOINT,
  accessTokenName: 'accessToken'
});
