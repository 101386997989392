// @flow
import { connect } from 'react-redux';
import type { State, Dispatch } from 'shared/types';
import Toast from './Toast.component';
import { hideToast } from './Toast.actions';

const mapStateToProps = (state: State) => ({
  toast: state.toast
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  hideToast: () => dispatch(hideToast())
});

export default connect(mapStateToProps, mapDispatchToProps)(Toast);
