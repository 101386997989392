import styled, { css } from 'styled-components';
import StyledIconWrapper from './CloseButton/IconWrapper';
import Message from './Message';
import { TOAST_CONTEXT_ERROR } from './Toast.constants';

const visibleStyles = css`
  transform: translate(0, 0);

  ${/* sc-selector */Message},
  ${/* sc-selector */StyledIconWrapper} {
    opacity: 1;
  }
`;

const visibleDesktopStyles = css`
  transform: translate(-50%, 0);
`;

const desktopStyles = css`
  width: 675px;
  border-radius: 0 0 6px 6px;
  left: 50%;
  transform: translate(-50%, -100%);
  ${props => props.visible && visibleDesktopStyles}
`;

const errorContextStyles = css`
  background-color: ${props => props.theme.colors.error};
`;

export default styled.div`
  left: 0;
  right: 0;
  background-color: #1bd390;
  height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 101;
  transform: translate(0, -100%);
  top: 0;
  padding-left: 64px;
  padding-right: 64px;
  transition: transform ${props => props.theme.animations.slide};
  will-change: transform;

  ${/* sc-selector */Message},
  ${/* sc-selector */StyledIconWrapper} {
    opacity: 0;
    transition: opacity ${props => props.theme.animations.fade};
  }

  ${props => props.visible && visibleStyles}
  ${props => props.theme.breakpoints.sm`${desktopStyles}`}
  ${props => props.context === TOAST_CONTEXT_ERROR && errorContextStyles}
`;
