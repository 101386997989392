// @flow
class StorageService {
  store: Storage;

  constructor(store: Storage) {
    this.store = store;
  }

  getItem: (key: string) => ?string

  getItem(key: string) {
    return this.store.getItem(key);
  }

  setItem: (key: string, value: string) => void

  setItem(key: string, value: string) {
    return this.store.setItem(key, value);
  }

  removeItem: (key: string) => void

  removeItem(key: string) {
    return this.store.removeItem(key);
  }

  clear: () => void

  clear() {
    return this.store.clear();
  }
}

export default StorageService;
