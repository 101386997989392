// @flow
import React from 'react';
import type { ToastAction } from 'shared/types/toast';
import { CloseIcon } from 'shared/components/Icons';
import StyledIconWrapper from './IconWrapper';

type Props = {
  closeToast: () => ToastAction
}

const CloseButton = ({ closeToast }: Props) => (
  <StyledIconWrapper size={40} onClick={closeToast}>
    <CloseIcon />
  </StyledIconWrapper>
);

export default CloseButton;
