// @flow
import React from 'react';
import type { Toast, ToastAction } from 'shared/types/toast';
import StyledToast from './Toast.styled';
import CloseButton from './CloseButton';
import Message from './Message';
import { TOAST_VISIBLE_FOR } from './Toast.constants';

type Props = {
  toast: Toast,
  hideToast: () => ToastAction
};

class ToastComponent extends React.PureComponent<Props> {
  timer: ?TimeoutID

  constructor() {
    super();

    this.timer = null;
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.toast.visible !== prevProps.toast.visible) {
      if (this.props.toast.visible) {
        this.timer = setTimeout(() => {
          this.props.hideToast();
        }, TOAST_VISIBLE_FOR);
      } else {
        clearTimeout(this.timer);
      }
    }
  }

  render() {
    const { hideToast } = this.props;
    const { visible, message, context } = this.props.toast;
    return (
      <StyledToast visible={visible} context={context}>
        <Message>{message}</Message>
        <CloseButton closeToast={hideToast} />
      </StyledToast>
    );
  }
}

export default ToastComponent;
