// @flow
import * as React from 'react';
import { Route, Redirect } from 'react-router-dom';
import type { ContextRouter } from 'react-router-dom';
import withAuth from 'shared/HOC/withAuth';
import AuthService from 'shared/services/Auth/Auth.service';

type Props = {
  authenticated?: boolean,
  component: React.ComponentType<*>,
  authService: typeof AuthService
};

class PrivateRoute extends React.PureComponent<Props> {
  static defaultProps = {
    authenticated: true
  }

  renderRoute = (router: ContextRouter) => {
    const {
      component: Component, authenticated, authService
    } = this.props;
    if (authService.loggedIn()) {
      if (authenticated) {
        return <Component {...router} />;
      }
      return <Redirect to={{ pathname: '/' }} />;
    }
    if (authenticated) {
      return <Redirect to={{ pathname: '/login' }} />;
    }
    return <Component {...router} />;
  }

  render() {
    const {
      authenticated: omitAuthenticated, authService: omitAuthService,
      component: Component, ...rest
    } = this.props;
    return (
      <Route
        {...rest}
        render={this.renderRoute}
      />
    );
  }
}

export default withAuth(PrivateRoute);
